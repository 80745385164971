.header-section {
  background-color: #ffffff;
  position: fixed;
  z-index: 100;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.header-logo{
  color: #0173BC;
  font-size: 28px;
  text-decoration: none;
  font-weight: 500;

}
.header-content ul{
  padding-left: 0;
}
.header-content h1 {
  font-size: 25px;
  font-family: "LexendFontMedium", sans-serif;
  line-height: normal;
  color: #03293e;
  display: none;
  white-space: nowrap;
}

.header-content img {
  width: 80%;
}

.header-menu {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0;
}

.header-menu li a, .logout-link {
  text-decoration: none;
  font-weight: 400;
  font-size: 14px;
  white-space: nowrap;
  color: #0173BC;
  margin: 0 8px;
  width: 80%;
  font-family: "LexendFontReqular", sans-serif;
  cursor: pointer;
}



.header-menu li a:hover,
.header-menu li a.active, .header-content li .logout-link:hover {
  color: #f99c39;
  font-size: 16px;
}

/* .header-menu li div:hover, 
.header-menu li div.active {
  color: #f99c39;
  font-size: 16px;
} */
.hamburger-icon {
  display: none;
  z-index: 800;
  color: #f99c39;
  font-size: 18px;
}

@media screen and (max-width: 1026px) {
  .header-section {
    padding: 1rem 1.5rem !important;
  }

  .hamburger-icon {
    display: block;
  }

  .header-menu.active {
    right: 0px;
    color: #ffffff;
  }

  .header-menu.active li {
    margin: 15px 0;
  }

  .header-menu li a {
    font-size: 20px;
    color: #ffffff;

  }
  .header-menu li a:hover,
  .header-menu li a.active {
    font-size: 22px;
  }
  .header-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -1025px;
    height: 100vh;
    background-color: #03293e;
    transition: 0.3s ease-in-out;
    z-index: 100;
  }

  .hamburger-icon .iconify {
    font-size: 50px;
  }
  .hamburger-icon .bar {
    color: #0173BC;

  }
  .hamburger-icon .close {
    color: #f99c39;
    height: 70px;
    width: 70px;
    border-radius: 50px;
    padding: 5px;
    border: 2px solid;
  }

  .header-section {
    padding: 1rem 1.5rem !important;
  }
  .header-content h1 {
    display: flex;
  }
}


@media screen and (max-width: 768px) {
  .header-section {
    padding: 0.5rem !important;
  }

  .header-content img {
    width: 60%;
  }

  .hamburger-icon .iconify {
    font-size: 40px;
  }

  .header-menu li a {
    font-size: 18px;
  }

  .header-content h1 {
    font-size: 20px;
    margin-left: -95px;
  }

  .hamburger-icon .close {
    height: 45px;
    width: 45px;
  }
}


@media screen and (max-width: 426px) {
  .header-content img {
    width: 40%;
    margin-right: 0 !important;
}
.header-content h1 {
  font-size: 15px;
  margin-left: -115px;
}
.hamburger-icon .iconify {
  font-size: 30px;
}
.hamburger-icon .close {
  height: 30px;
  width: 30px;
}
.header-menu li a {
  font-size: 14px;
}
.header-content h1 {
  font-size: 12px;
}
.header-content img {
  width: 35%;
}
}
@media screen and (max-width: 320px) {
  .header-content h1 {
    display:none
  }
  
}