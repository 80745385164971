.add-employee-content h5 {
  font-size: 34px;
  font-weight: 600;
  line-height: normal;
  color: #0280ca;
}

.add-employee-content .form-field {
  display: flex;
  align-items: center;
  width: 400px;
  /* justify-content: center; */
  padding: 5px 20px;
  border-radius: 10px;
  border: 1px solid #444444;
  margin: 10px solid;
}

.add-employee-content .form-field input {
  width: 100%;
  padding: 10px 0;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 15px;
  line-height: normal;
  color: #444444;
  text-overflow: ellipsis;
}

.add-employee-content .form-field select option {
  color: #000;
  font-size: 12px;
}

.add-employee-content .form-field select option:first-child {
  color: #444444ac !important;
}

.add-employee-content .form-field select {
  /* width: 400px; */
  width: -webkit-fill-available;
  padding: 10px 0;
  outline: none;
  border: none;
  color: #444444ac;
  background-color: transparent;
  font-size: 15px;
  line-height: normal;
  text-overflow: ellipsis;
}

.add-employee-content .form-field input::placeholder {
  color: #444444ac;
  background-color: transparent !important;
}

.add-employee-content .form-field input:-internal-autofill-selected {
  appearance: none !important;
  background-image: none !important;
  background-color: -internal-light-dark(transparent, transparent) !important;
  color: transparent !important;
}

.add-employee-content .form-field input:-webkit-autofill,
.add-employee-content .form-field input:-webkit-autofill:hover,
.add-employee-content .form-field input:-webkit-autofill:focus,
.add-employee-content .form-field input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

/* Chrome, Safari, Edge, Opera */
.add-employee-content .form-field input::-webkit-outer-spin-button,
.add-employee-content .form-field input::-webkit-inner-spin-button,
.add-employee-content .form-field input[type="number"] {
  -webkit-appearance: none;
  margin: 0;
  -moz-appearance: textfield;
  /* -moz-appearance: textfield; */
}

.add-employee-content .form-field select {
  appearance: none;
  background-image: url("../../../assets//arrow.png");
  background-repeat: no-repeat;
  background-position: right 15px;
  background-size: 1em auto;
}

.profile-img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.profile-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-img .edit-camera {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 48rem;
  left: 45rem;
  background: #ffffff;
  border: 1px solid rgba(68, 68, 68, 0.16);
  border-radius: 231.25px;
 
}

.profile-img .edit-camera .edit-camera-icon {
  height: 20px;
  width: 20px;
  color: #0173bc;

}

.profile-img .camera {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 30px;
  height: 30px;
  top: 7;
  left: 720px;
  background: #ffffff;
  border: 1px solid rgba(68, 68, 68, 0.16);
  border-radius: 231.25px;
}

.profile-img .camera .camera-icon {
  height: 20px;
  width: 20px;
  color: #0173bc;
}

.picture-upload-section p.headshot-title {
  font-size: 20px;
  line-height: normal;
  color: #000000;
  font-family: "LexendFontReqular", sans-serif;
  white-space: nowrap;
}

.picture-upload-section p.headshot-title span {
  font-size: 14px;
  font-weight: 200;
  width: 100px;
  white-space: pre-wrap;
}

.add-employee-content .btn.save-employee {
  width: 400px;
  padding: 10px;
  border-radius: 50px;
  background: #0173bc;
  color: #ffffff;
  font-size: 28px;
  font-weight: 400;
  line-height: normal;
  white-space: nowrap;
}

@media screen and (max-width: 1026px) {
  .profile-img .camera {
    left: 595px;
  }
}

@media screen and (max-width: 992px) {
  .profile-img .camera {
    left: 580px;
  }
}

@media screen and (max-width: 800px) {
  .add-employee-content .form-field {
    width: 300px;
  }

  .profile-img .camera {
    left: 490px;
    top: 673px
  }

  .picture-upload-section p.headshot-title span {
    font-size: 10px;

  }

  .picture-upload-section p.headshot-title {
    font-size: 16px;
    width: 175px;
  }
}

@media screen and (max-width: 769px) {
  .add-employee-content h5 {
    font-size: 28px;
  }

  .add-employee-content .btn.save-employee {
    width: 250px;
    font-size: 20px;
  }

  .profile-img .camera {
    left: 470px;
    top: 665px;
  }

  .picture-upload-section p.headshot-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 600px) {
  .add-employee-content h5 {
    font-size: 24px;
  }

  .profile-img {
    width: 60px;
    height: 60px;
    margin-left: 0 !important;
  }

  .profile-img .camera .camera-icon {
    height: 15px;
    width: 15px;
  }

  .profile-img .camera {
    width: 20px;
    height: 20px;
    top: 558px;
    left: 345px;
  }

  .add-employee-content form .addform div.d-flex.my-4 {
    margin: 1rem 0 !important;
  }

  .add-employee-content .form-field {
    width: 260px;
    padding: 5px 10px;

  }

  .add-employee-content .form-field select,
  .add-employee-content .form-field input {
    padding: 5px 0;
    font-size: 14px;
  }

  .add-employee-content .btn.save-employee {
    width: 200px;
  }

  .add-employee-content .form-field select {
    background-size: 0.8em auto;
    background-position: right center;
  }
}

@media screen and (max-width: 568px) {
  .profile-img .camera {
    left: 330px;
  }

  .add-employee-content .form-field {
    width: 230px;
  }
}

@media screen and (max-width: 522px) {
  .profile-img .camera {
    left: 303px;
    top: 560px;
  }

  .add-employee-content .form-field {
    width: 220px;
  }
}

@media screen and (max-width: 426px) {
  .add-employee-content {
    margin-top: 0 !important;
  }

  .add-employee-content h5 {
    font-size: 20px;
  }

  .profile-img .camera {
    left: 330px;
  }

  .add-employee-content form,
  .add-employee-content form .last-div {
    display: flex;
    flex-direction: column;
    align-items: center !important;
  }

  .add-employee-content .form-field {
    width: 320px;
    margin: 11px 0 !important;
  }

  .profile-img .camera {
    top: 1008px;
    left: 120px;
  }

  .profile-img {
    width: 75px;
    height: 75px;
  }

  .picture-upload-section p.headshot-title {
    width: auto;
  }

  .add-employee-content .btn.save-employee {
    width: 180px;
    font-size: 16px;
  }
}

@media screen and (max-width: 376px) {
  .profile-img .camera {

    left: 95px;
  }

}

@media screen and (max-width: 320px) {

  .profile-img .camera {
    top: 985px;
    left: 64px;
  }

  .add-employee-content .form-field {
    width: 250px;
  }

  .profile-img {
    width: 55px;
    height: 55px;
  }
}