.forgotpassword-screen {
  height: 100vh;
  background-color: #03293e;
  display: flex;
  align-items: center;
  justify-content: center;
}

.forgotpassword-screen .form {
  width: 600px;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  border-radius: 15px;
}

.forgotpassword-screen h1 {
  font-weight: 600;
  font-size: 36px;
  color: #000000;
}

.forgotpassword-screen p {
  font-weight: 400;
  font-size: 14px;
  color: #000000;
  line-height: normal;
  text-align: center;
  margin-top: 20px;
}

.forgotpassword-screen p span, .forgotpassword-screen p span a {
  text-decoration: none;
  color: #fd8c1b;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
}

.forgotpassword-screen input {
  width: 350px;
  border: 1px solid #444444;
  border-radius: 8px;
  padding: 10px;
  font-size: 20px;
}
.forgotpassword-screen input::placeholder {
  font-size: 14px;
}

.forgotpassword-screen input.email-input {
  width: 450px;
  border: 1px solid #444444;
  border-radius: 8px;
  padding: 10px;
  font-size: 14px;
}

.forgotpassword-screen .password {
  /* width: 400px; */
  border: 1px solid #444444;
  border-radius: 8px;
  padding: 2px 10px;
  margin: 3px 2px;
}

.password input,
.password input:hover,
.password input:focus {
  outline: none;
  border: none;
  background-color: transparent;
  padding: inherit;
}

.password input[type="text"]:enabled {
  background: transparent;
}

.password input[type="text"]:disabled {
  background: transparent;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.forgotpassword-screen input:hover,
.forgotpassword-screen input:focus,
.forgotpassword-screen .otp-input input:hover,
.forgotpassword-screen .otp-input input:focus {
  outline: none;
  border: 1.3px solid #fb9129;
}

.form .forgotpassword-btn {
  background: #0280ca;
  color: #ffffff;
  width: 200px;
  padding: 10px;
  border-radius: 55px;
  font-size: 22px;
  font-weight: 400;
  /* max-height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* line-height: normal; */
}

.forgotpassword-screen .otp-input input {
  width: 50px;
  height: 50px;
  background: #efefef;
  border: 1px solid #a19e9e;
  border-radius: 10px;
  border: 0.5px solid #adadad;
  border-radius: 4px;
  font-size: 18px;
  text-align: center;
  line-height: normal;
  margin: 0.5rem;
}

.forgotpassword-screen .otp-input input::-webkit-outer-spin-button,
.forgotpassword-screen .otp-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p.create {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  color: #000000;
  margin-top: 5px;
  margin-bottom: 0;
}

.forgotpassword-screen .btn.profile-saved {
  width: 400px;
  /* height: 45px; */
  background: #444444;
  border-radius: 4px;
  color: #ffffff;
}

.forgotpassword-screen .btn.profile-saved p {
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  margin: 0 10px;
}

p.error, .error  {
  color: red;
  font-weight: 700;
  font-size: 10px;
  margin-top: 10px;
}

.snack-bar{
    width: 238px;
    border-radius: 8px;
    background-color: #0280ca;
    color: #ffffff;
    font-size: 16px;
    padding: 10px;
    position: fixed;
    z-index: 200;
    top: 10px;
    white-space: nowrap;
    text-align: center;
}
@media screen and (max-width: 768px) {
  .forgotpassword-screen .form h1 {
    font-size: 25px;
  }
  .forgotpassword-screen .form {
    width: 550px;
  }
  .form .forgotpassword-btn {
    padding: 8px;
  }
  .snack-bar{
    top: 17px;
   }
}

@media only screen and (max-width: 600px) {

  .forgotpassword-screen .form {
    width: 450px;
    padding: 1rem !important;
  }
  .snack-bar{
    top: 50px;
   }
  
}


@media screen and (max-width: 426px) {
  .forgotpassword-screen .form {
    width: 350px;
  }
  .forgotpassword-screen .form h1 {
    font-size: 20px;
}
.forgotpassword-screen input {
  width: 300px;
  padding: 8px;
}
.form .forgotpassword-btn {
  width: 150px;
  font-size: 16px;
  padding: 5px;
}
.forgotpassword-screen .otp-input {
  display: flex;
  flex-wrap: nowrap;
}
.forgotpassword-screen .otp-input input {  
  width: 40px;
  height: 40px;
  font-size: 16px;
  padding: 5px;
  margin: 0.3rem;
}
.snack-bar{
  width: 150px;
  top: 50px;
 }
}

@media screen and (max-width: 375px) {
 .forgotpassword-screen .form form.my-5{
  margin-top: 20px !important;
  margin-bottom: 0 !important;
 }
 .forgotpassword-screen  p:nth-child(2){
  margin-top: 0;
 }
 

}


@media screen and (max-width: 320px) {
  .forgotpassword-screen .form {
    width: 250px;
}
.forgotpassword-screen .form h1 {
    font-size: 16px;
}
.forgotpassword-screen p {
  font-size: 12px;
}
.forgotpassword-screen p span, .forgotpassword-screen p span a{
  font-size: 13px;
}
.forgotpassword-screen .otp-input input {
  width: 35px;
  height: 35px;
  font-size: 14px;
  padding: 5px;
  margin: 0 0.1rem;
}
.forgotpassword-screen input {
  width: 220px;
  font-size: 14px;
}
.form .forgotpassword-btn {
  width: 100px;
  font-size: 14px;
}
.form-field{
  margin-top: 10px !important;
}
.snack-bar{
  top: 80px;
 }
}