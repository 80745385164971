.Admin-dashboard {
  display: flex;
}

.admin-dashboard h3{
  color: #ffffff;
}
.sidebar {
  position: fixed;
  width: 250px;
  height: 100vh;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: #0280ca;
  z-index: 300;
}
.sidebar-user-info{
  overflow: hidden;
}
.admin-hamburger-icon {
  display: none;
  z-index: 800;
  color: #f99c39;
  font-size: 18px;
}

.sidebar img {
  width: 70%;
  margin-bottom: 10px;
}

.sidebar span.name {
  font-size: 15px;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
}

.sidebar .sidebar-navlinks p {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 8px 10px;
  margin: 10px 0;
  width: 250px;
  overflow-y: hidden;
}

.sidebar p span {
  font-size: 14px;
  font-weight: normal;
  line-height: normal;
  margin: 0 10px;
  color: #ffffff;
  white-space: nowrap;
}

.sidebar p.active,
.sidebar p:hover {
  border-left: 4px solid #fb9129;

  background: #141414c1;
  white-space: nowrap;
}

.sidebar p .iconify {
  font-size: 20px;
  color: #ffffff;
  font-weight: 700;
}

.sidebar p.active .iconify,
.sidebar p:hover .iconify {
  color: #fb9129;
}

.content {
  width: auto;
  margin-left: 250px;
  padding: 20px;
  overflow-y: auto;
}

.admin-dashboard .content h4 {
  font-size: 26px;
  font-weight: 700;
  line-height: normal;
  color: #0280ca;
}

.back-icon {
  width: 25px;
  height: 25px;
  background-color: #0d9068;
  color: #ffffff;
  border-radius: 20px;
}

.user-info-section {
 
}

/* .user-info-section{
    width: auto;
    margin-left: 225px;
  }

} */

@media screen and (max-width: 992px) {
  .sidebar .sidebar-navlinks p {
    width: auto;
  }
  .admin.hamburger-icon {
    display: block;
    position: absolute;
    left: 30px;
    transform: scaleX(-1);
  }

  .sidebar .header-menu.active {
    right: 0px;
    color: #ffffff;
  }

  .sidebar .header-menu.active li {
    margin: 15px 0;
  }

  .sidebar {
    display: none;
  }

  .sidebar.open {
    display: flex;
  }

  .sidebar .header-menu li a {
    font-size: 20px;
    color: #ffffff;

  }

  .sidebar-navlinks {
    margin-top: 0 !important;

  }

  .sidebar .header-menu {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 0;
    right: -1025px;
    height: 100vh;
    background-color: #0280ca;
    transition: 0.3s ease-in-out;
    /* z-index: 100; */
  }
  

  .sidebar p span {
    font-size: 22px;
    font-weight: 500;
  }

  .sidebar p.active,
  .sidebar p:hover,
  .sidebar p.active span {
    border: 0;
    background: transparent;
    color: #fb9129 !important;
    white-space: nowrap;
  }
  .admin-dashboard{
    padding: 30px 0;
  }

  .content {
    margin-left: 0;
    margin-top: 50px;
  }
  .sidebar p .iconify {
    display: none;
  }
}

@media only screen and (max-width: 426px) {
  .admin-dashboard .content h4, .admin-dashboard h3 {
      font-size: 20px;
  }
}
@media only screen and (max-width: 376px) {

.content {
  margin-left: 0;
  margin-top: 30px;
}
}