.attendance-analytic-page h1 {
  font-size: 24px;
  font-weight: 600;
  line-height: normal;
  color: #0280ca;
}

.attendance-analytic-page h5 {
  font-size: 20px;
  font-weight: 600;
  line-height: normal;
  color: #0280ca;
  white-space: nowrap;
}

.beneficiary-statistics .box {
  width: 230px;
  padding: 10px;
  border-radius: 8px;
  background: #e3e3e366;
  color: #444444;
  font-size: 16px;
  font-weight: 400;
  line-height: normal;
  text-align: center;
}

.beneficiary-statistics .box h2 {
  font-size: 34px;
  font-weight: 800;
  line-height: normal;
  color: #0280ca;
}

.attendance-analytic-page .filter-option-section .form-field select {
  background-image: url("../../../assets/arrow-blue.png");
  cursor: pointer;
}

.analytic-report-page .filter-option-section .form-field select option:nth-child(1) {

  color: #7a7575;

}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-right {
  justify-content: flex-end;
  right: 0px;
  position: relative !important;
  left: 0px;
  padding: 46px !important;
}

.apexcharts-legend.apx-legend-position-bottom.apexcharts-align-right,
.apexcharts-legend.apx-legend-position-top.apexcharts-align-right:nth-child(2) {
  margin: 0 2px;
}

.attendance-analytic-page .tab-headers {
  display: flex;
  margin-bottom: 10px;
  border-bottom: 1px solid;
}

.attendance-analytic-page .tab-header {
  padding: 10px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 400;
  line-height: normal;
  border: none;
  white-space: nowrap;

}

.attendance-analytic-page .active {
  color: #0280CA;
  border-bottom: 2.5px solid #0280CA;
  font-weight: 500;
}






.analytic-report-page .employee-list-table .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 10px 10px 0 0;
  border: 1px solid #C2C2C2;
}

.analytic-report-page .employee-list-table .css-15wwp11-MuiTableHead-root {

  background: #D9D9D926;

}

.analytic-report-page .employee-list-table .css-1ygcj2i-MuiTableCell-root {
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
  color: #444444;
  white-space: nowrap;
}

.analytic-report-page .employee-list-table .css-1wlk0hk-MuiAvatar-root {
  width: 50px;
  height: 50px;
}

.analytic-report-page .employee-list-table .css-1ex1afd-MuiTableCell-root {
  letter-spacing: 0px;
  border-bottom: 1px solid #C2C2C2;
  white-space: nowrap;
  padding: 15px;
  color: #000000;
  font-size: 14px;
  font-weight: 300;
  line-height: normal;
}

.analytic-report-page .employee-list-table .css-1ex1afd-MuiTableCell-root .days-worked p {

  font-size: 14px;
}

.analytic-report-page .employee-list-table .css-1ex1afd-MuiTableCell-root .days-worked {
  font-weight: 500;
  font-size: 11px;
}

.analytic-report-page .employee-list-table .ward,
.analytic-report-page .employee-list-table .ward {
  text-align: center;
}


.days-worked .tickred {
  color: red;
}

.days-worked .tickgreen {
  color: #008000;
}


.graph-filter-section .btn.export-btn {
  color: #ffffff;
  background-color: #0280CA;
  text-transform: capitalize;
  font-family: Lato;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  padding: 10px;
  white-space: nowrap;

}

.attendance-analytic-page .employee-list-table {
  padding: 0 !important;
}


.export-pdf-header {
  background-color: #f5f5f5;
  font-weight: bold;
  text-align: center;
  font-size: 13px;  
  /* visibility: hidden; */
}

.export-pdf-header .logo-export-image{
  height: auto;
  width: 120px;
}
.export-pdf-header img{
  width: 100%;
  object-fit: contain;
}
.export-pdf-header h5, .export-pdf-header span{
  font-size: 16px;
  color: #0280CA;
}



@media screen and (max-width: 1026px) {
  .graph-filter-section .filter-option-section .form-field select {
    width: 96px;
  }

  .graph-filter-section .search-button {
    width: 160px;
  }
}

@media screen and (max-width: 992px) {}


@media screen and (max-width: 768px) {
  .graph-filter-section {
    align-items: center;
  }

  .attendance-analytic-page .summary-card {
    margin-top: 30px;
  }

  .attendance-analytic-page .analytic-filter-section {
    flex-direction: column;
    align-items: flex-start !important;
  }
}

@media screen and (max-width: 600px) {
  .admin-dashboard .content h4 {
    font-size: 20px;
  }

  .graph-filter-section {
    display: grid !important;
  }

  .graph-filter-section .btn.export-btn {
    margin: 15px 0 0 0 !important;

  }

  .attendance-analytic-page .tab-header.mx-5 {
    margin: 0 !important;
  }

  .attendance-analytic-page .tab-headers {
    /* justify-content: space-between; */
  }
}

@media screen and (max-width: 576px) {
  .graph-filter-section .filter-option-section {
    padding: 5px 0;
  }

  .graph-filter-section .filter-option-section .form-field select {
    width: 115px;
  }

  .graph-filter-section .filter-option-section .form-field select {
    background-position: 74px center;
  }

}

@media screen and (max-width: 522px) {
  .content {
    padding: 0 5px !important;
  }

  .attendance-analytic-page .filter-option-section .form-field select {
    width: 130px;
    padding: 10px 20px 10px 0;
    background-position: 110px center;
  }

  .graph-filter-section .filter-option-section {
    padding: 5px 0;
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px 60px;
  }

  .attendance-analytic-page .filter-option-section {
    margin-top: 0;
  }

  .analytic-report-page .graph-filter-section .filter-option-section .form-field {
    margin: 0 !important;
  }

  .graph-filter-section .filter-option-section .search-button {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .graph-filter-section .btn.export-btn {
    width: 160px;
  }

  .attendance-analytic-page .card p {
    width: 80px;
    white-space: normal;
  }

}

@media screen and (max-width: 480px) {

  .attendance-analytic-page .filter-option-section .form-field select {
    width: 110px;
    padding: 10px 30px 10px 0;
  }
}

@media screen and (max-width: 426px) {

  .content {
    padding: 0 1rem !important;
  }

  .attendance-analytic-page .analytic-filter-section .filter-option-section .form-field {
    width: auto;
  }


  .attendance-analytic-page .filter-option-section .form-field select {
    width: 130px;
    padding: 10px 20px 10px 0;
    background-position: 110px center;
  }

  .graph-filter-section .filter-option-section .form-field {
    width: 155px;
  }

  .admin-dashboard .content h4 {

    margin-top: 10px;
  }
}

@media screen and (max-width: 376px) {
  .attendance-analytic-page .filter-option-section {

    display: grid !important;
    grid-template-columns: 1fr 1fr;
    gap: 10px;
  }

  .attendance-analytic-page .filter-option-section .form-field select {
    width: 115px;
    background-position: 100px center;
  }

  .graph-filter-section .filter-option-section .form-field,
  .graph-filter-section .search-button, .graph-filter-section .btn.export-btn {
    width: 150px;

  }

  .attendance-analytic-page .card p {
    width: auto;
    white-space: nowrap;
  }
}

@media screen and (max-width: 320px) {
  .graph-filter-section .filter-option-section .form-field, .graph-filter-section .search-button, .graph-filter-section .btn.export-btn {
    width: 130px;
}
.attendance-analytic-page .filter-option-section .form-field select {
  width: 100px;
  background-position: 88px center;
}
}