.filter-option-section.admin p {
  font-size: 36px;
  font-weight: 600;
  line-height: normal;
  color: #0280ca;
}

.css-1yk1gt9-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root {
  width: 200px;
  border-radius: 16px !important;
  letter-spacing: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
  color: #7a7575;
}

.css-ahj2mt-MuiTypography-root,
.css-ahj2mt-MuiTypography-root:first-child {
  margin: 0;
  font-weight: 400;

  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.filter-option-section.admin .form-field {
  border-radius: 33px;
  border: 0.6px solid #444444;
  padding: 0 15px;
}

.filter-option-section.admin .form-field select {
  width: 130px;
  padding: 10px 0;
  outline: none;
  border: none;
  color: #7a7575;
  background-color: transparent;
  font-size: 14px;
  line-height: normal;
  appearance: none;
  background-image: url("../../../assets/arrow.png");
  background-repeat: no-repeat;
  background-position: center right;
  background-size: 0.8em auto;
}

.filter-option-section.admin .form-field select option:first-child {
  color: #a6a6a68c;
}

.filter-option-section.admin .search-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 200px;
  border-radius: 45px;
  border: 0.6px solid #444444;
  color: #444444;
  height: fit-content;
  margin: 0 !important;
}

.search-icon {
  font-size: 25px;
  color: #7a7575;
}

.workSector-arrow {
  font-size: 15px;
  color: #f99c39;
}

.filter-option-section.admin .search-button input {
  /* width: 400px; */
  padding: 10px 0;
  outline: none;
  border: none;
  background-color: transparent;
  font-size: 14px;
  line-height: normal;
  color: #7a7575;
}

.filter-option-section.admin .search-button input::placeholder {
  color: #7a7575;
  background-color: transparent !important;
}

.filter-option-section.admin .search-button input:-internal-autofill-selected {
  appearance: none !important;
  background-image: none !important;
  background-color: -internal-light-dark(transparent, transparent) !important;
  color: transparent !important;
}

.filter-option-section.admin .search-button input:-webkit-autofill,
.filter-option-section.admin .search-button input:-webkit-autofill:hover,
.filter-option-section.admin .search-button input:-webkit-autofill:focus,
.filter-option-section.admin .search-button input:-webkit-autofill:active {
  -webkit-transition-delay: 9999s;
  transition-delay: 9999s;
}

.filter-option-section.admin .form-field select option {
  background-color: #fff;
  color: #333;
  padding: 8px;
}

.filter-option-section.admin .form-field select option[value="option-value"] {
  font-weight: bold;
  color: #ff0000;
}
.options-bold {
  font-weight: 900;
}

.list-style-none {
  list-style: none;
  cursor: pointer;
}
.exportBtn {
  color: #fff;
  display: flex;
  padding: 0.2rem 0.5rem;
  justify-content: space-evenly;
  align-items: center;
  background-color: #0280ca;
  border-radius: 5px;
  white-space: nowrap;
}
.filter-option-section.admin.for-sector {
}

.sector-drop-down {
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.15);
  position: absolute;
  left: 57rem;
  width: 300px;
  top: 17rem;
  z-index: 44;
  border-radius: 4px;
}

.inner-dropdown-select {
  background-color: #fff;
  box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.15);
  position: relative;
  margin-left: 2rem;
  margin-top: 14px;
  margin-bottom: 14px;
  border-radius: 4px;
  z-index: 777;
}

@media screen and (max-width: 1027px) {
  .filter-option-section.admin .filter {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .filter-option-section.admin .form-field {
    width: 200px;
  }

  .exportBtn {
    margin-top: 14px;
    width: 150px;
  }

  .filter-option-section.admin .form-field select {
    width: -webkit-fill-available;
  }
}

@media only screen and (max-width: 768px) {
  .employee-list-table {
    padding: 0 10px !important;
  }

  .filter-option-section.admin .form-field {
    width: 160px;
    margin: 0 10px 0 0 !important;
  }

  .filter-option-section.admin .filter {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }

  .filter-option-section.admin .search-button {
    width: 160px;
    margin: 0 10px 0 0 !important;
  }

  .exportBtn {
    margin-top: 4px;
  }
}

@media only screen and (max-width: 600px) {
  .filter-option-section.admin .filter {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

@media only screen and (max-width: 522px) {
  .filter-option-section.admin .form-field,
  .filter-option-section.admin .search-button {
    width: 150px;
    margin: 5px 0 !important;
  }
}

@media only screen and (max-width: 426px) {
  .filter-option-section.admin .form-field,
  .filter-option-section.admin .search-button {
    width: 180px;
  }
  .filter-option-section.admin .filter {
    grid-template-columns: 1fr 1fr;
  }
}

@media only screen and (max-width: 376px) {
  .filter-option-section.admin .form-field,
  .filter-option-section.admin .search-button {
    width: 155px;
    margin: 5px !important;
  }
}

@media only screen and (max-width: 320px) {
  .filter-option-section.admin .filter {
    grid-template-columns: 1fr;
  }
  .filter-option-section.admin .form-field,
  .filter-option-section.admin .search-button {
    width: 250px;
  }

  .employee-list-table {
    padding: 0 !important;
  }
}

.sub-select {
  display: none;
}
