.empty-list-screen {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100vh;
}

.empty-list-screen .empty-list-content img {
  width: 15%;
}

.empty-list-screen .empty-list-content p {
  font-size: 24px;
  font-weight: 750;
  line-height: normal;
  color: #444444;
  margin-top: 5px;
}

.empty-list-screen .empty-list-content span {
  font-size: 20px;
  font-weight: 300;
  line-height: normal;
  color: #444444;
  width: 400px;
  margin: 10px 0;
}

.empty-list-screen .empty-list-content .btn.add-employee {
  width: 300px;
  text-align: center;
  padding: 10px;
  background: #0173bc;
  border-radius: 45px;
  font-size: 24px;
  font-weight: 500;
  line-height: normal;
  color: #ffffff;
}
.table-typology{
  /* max-width: 100px;
  text-overflow: ellipsis; */
  width: 100px;
  white-space: normal;
}
.empty-list-screen .empty-list-content .btn.add-employee .iconify {
  font-size: 30px;
}

/*   Table   */
.employees-table-section a {
  text-decoration: none;
  color: inherit;
}

.employees-table-section .search-button {
  margin: 0;
}

.employees-table-section .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  border-radius: 10px 10px 0 0;
}

.employees-table-section .css-15wwp11-MuiTableHead-root {
  background-color: #0281ca49;
}

.employees-table-section .css-1ygcj2i-MuiTableCell-root {
  font-size: 20px;
  font-weight: 500;
  line-height: normal;
  color: #444444;
}

.employees-table-section .css-1wlk0hk-MuiAvatar-root {
  width: 50px;
  height: 50px;
}

.employees-table-section .css-1ex1afd-MuiTableCell-root {
  letter-spacing: 0px;
  border-bottom: 1px solid #00000090;

  padding: 10px 16px;
  color: #000000;
  font-size: 15px;
  font-weight: 300;
  line-height: normal;
}

/* CSS for the floating button */
.floating-button {
  position: fixed;
  bottom: 30px;
  right: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0173bc;
  color: #fff;
  border-radius: 25px;
  padding: 10px 20px;
  white-space: nowrap;
  border: none;
  outline: none;
  cursor: pointer;
  box-shadow: 0px 3px 30px 0px #00000080;
}

.floating-button span {
  margin-left: 10px;
  font-size: 16px;
}

/* .snackbar{
  position: fixed;
  width: 300px;
  display: flex;
  align-items: center;
padding: 5px 20px ;
background: #0173BC;
color: #ffffff;
font-size: 16px;

}
.snackbar .iconify.snackbar-icon{
  font-size: 24px;
  color: #ffffff;
} */
@media only screen and (max-width: 1027px) {
  .employees-table-section .filter-option-section.admin .filter {
    margin: 0 5px !important;
  }

}
@media  screen and (max-width: 800px) {
 .employees-table-section .filter-option-section {
    /* padding: 0 !important; */
}

}

@media only screen and (max-width: 768px) {
  .employees-table-section .search-button {
    width: 160px;
  }
  .employees-table-section .formfield{
    margin-top: 0 !important;
  }
}


@media only screen and (max-width: 426px) {
  .employees-table-section .search-button {
    width: 120px;
}
.exportBtn {
  /* margin-top */
  width: 95px
}
}

@media only screen and (max-width: 376px) {
  .employees-table-section .search-button {
    width: 140px;
}
.exportBtn {
  width: 140px
}

}

@media only screen and (max-width: 320px) {
  .employees-table-section .search-button {
    width: 230px;
    margin: 8px 0 !important;
}
.exportBtn {
  width: 230px
}
/* .employees-table-section .search-button {
  width: 230px;
  margin: 8px 0 0 0 !important;
} */
}